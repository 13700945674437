<template>
<div class="ny_pad">
	<div class="menu_ico">
	 <top_menu></top_menu>
	 <!-- ho_menu -->
	 </div>
	 <!-- menu_ico tz-->
	 <div class="about_lay " id="gonggao1">
	    <div class="about_wapper">


<!--	     <dl class="fontlay dj_lay wid_100b gonggao_tab">-->
<!--	       <p class="wid_100b text_cen"><img src="@/assets/images/icon31.png" /></p>-->
<!--	      <ul id="myTab1" class="riqi_tab">-->
<!--	       <li class="active" href="#taocan1" data-toggle="tab">2022 01/11</li>-->
<!--	       <li href="#taocan2" data-toggle="tab">2022 01/10</li>-->
<!--	       <li href="#taocan3" data-toggle="tab">2022 01/09</li>-->
<!--	      </ul>-->
<!--	      <dd id="myTabContent1">-->
<!--	       <div class="tab-pane fade active in" id="taocan1">-->
<!--	        <router-link :to="{path:'/content',query:{id:item.id}}" v-for="(item, index) in testdata" :key="index">-->
<!--	         <strong>{{(item.title||'').slice(0,17)}}</strong>-->
<!--	         {{(item.description||'').slice(0,30)}}-->
<!--	        </router-link>-->
<!--	       </div>-->
<!--	       <div class="tab-pane fade" id="taocan2">-->
<!--	        <router-link :to="{path:'/content',query:{id:item.id}}" v-for="(item, index) in testdata1" :key="index">-->
<!--	         <strong>{{(item.title||'').slice(0,17)}}</strong>-->
<!--	         {{(item.description||'').slice(0,30)}}-->
<!--	        </router-link>-->
<!--	       </div>-->
<!--	       <div class="tab-pane fade" id="taocan3">-->
<!--	        <router-link :to="{path:'/content',query:{id:item.id}}" v-for="(item, index) in testdata2" :key="index">-->
<!--	         <strong>{{(item.title||'').slice(0,17)}}</strong>-->
<!--	         {{(item.description||'').slice(0,30)}}-->
<!--	        </router-link>-->
<!--	       </div>-->
<!--	      </dd>-->
<!--	     </dl>-->
<!--	     &lt;!&ndash; dj_lay &ndash;&gt;-->
	    </div>
	    <!-- about_wapper -->
	  </div>
	  <!-- about_lay -->

  <div class="container">
    <div class="row"><img src="@/assets/images/icon32.png" /></div>
    <div class="row blog-left-content" v-for="(item, index) in testdata3" :key="index">


      <div class="content col-lg-8 col-md-12 col-sm-12">
        <H3><a :href="'/content?id='+item.id">{{(item.title||'')}}</a></H3>
        <!--                <p>{{(item.description||'')}}</p>-->
<!--        <a :href="'/content?'+item.id" class="read-btn">Read More</a>-->
      </div>

      <div class="blog-right col-lg-2 col-md-12 col-sm-12">
        {{(item.publishTime||'').slice(0,11).replace(/-/g,'/')}}<br/>
        <!--        {{(item.publishTime||'').slice(5,11).replaceAll('-','/')}}-->
      </div>


    </div>
    <div>
      <a :href="'/list?catid=72'"><img src="@/assets/images/m.jpg" /></a>
    </div>
  </div>

  <div class="container">
    <div class="row pd10" id="gonggao2"><img src="@/assets/images/xx.gif" /></div>
    <div class="row blog-left-content" v-for="(item, index) in testdata" :key="index">


      <div class="content col-lg-8 col-md-12 col-sm-12">
        <H3><a :href="'/content?id='+item.id">{{(item.title||'')}}</a></H3>
<!--        <p>{{(item.description||'')}}</p>-->
<!--        <a :href="'/content?'+item.id" class="read-btn">Read More</a>-->
      </div>

      <div class="blog-right col-lg-2 col-md-12 col-sm-12">
        {{(item.publishTime||'').slice(0,10).replace(/-/g,'/')}}
      </div>
    </div>

    <div>
      <a :href="'/list?catid=71'"><img src="@/assets/images/m.jpg" /></a>
    </div>
  </div>




	  <!-- qywh_lay -->
	 <fo_oter></fo_oter>
	<!-- footer -->
</div>
</template>
<style>

</style>
<script>
import top_menu from '../components/menu.vue'
import fo_oter from '../components/footer.vue'
import "@/assets/js/jquery-1.9.1.min.js";
import "@/assets/bootstrap/bootstrap.min.js";
import {GETDATA1,GETDATA2,GETDATA3,GETDATA4} from '../apis/gonggao.js'
export default {
  name: 'gonggao',
  components: {
    top_menu,fo_oter
  },
  data() {
    return {
		testdata:[],
		testdata1:[],
		testdata2:[],
		testdata3:[]
    };
  },
  mounted() {
  	this.onloading();
  },
  methods: {
  	async onloading(){
  	   this.testdata = await GETDATA1();
  	   this.testdata3 = await GETDATA4();
  	   this.testdata = this.testdata.rows;
  	   this.testdata3 = this.testdata3.rows;
  	}
  }
}
</script>