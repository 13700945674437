//home.js 用于获取首页json数据

import req from './http.js'  //引入封装好的axios

//在这里定义了一个登陆的接口，把登陆的接口暴露出去给组件使用
//这里使用了箭头函数，转换一下写法：
export const GETDATA1 =params=>req('get','cms/article/list/?catid=71&pageNum=1&pageSize=6'+'&orderColumn=sort desc,publish_time desc',params)
export const GETDATA2 =params=>req('get','cms/article/list/?catid=71&beginTime=20220110'+'&orderColumn=sort desc,publish_time desc',params)
export const GETDATA3 =params=>req('get','cms/article/list/?catid=71&beginTime=20220109'+'&orderColumn=sort desc,publish_time desc',params)
export const GETDATA4 =params=>req('get','cms/article/list/?catid=72&pageNum=1&pageSize=6'+'&orderColumn=sort desc,publish_time desc',params)
